import { useEffect } from 'react';
import dayjs from 'dayjs';
import { Filter } from 'components/molecules';
import { GetRemoteLessonsFilterKey, RemoteLessonsFilters } from 'types';
import { isBeforeSchoolYear } from 'utils/isBeforeSchoolYear';
import { remoteLessonsTexts } from 'consts/text';

type YearsRangeFilterProps = {
    filtersValues: RemoteLessonsFilters;
    onChangeFilters: (filterValues: Partial<Record<GetRemoteLessonsFilterKey, string>>) => void;
};

export const YearsRangeFilter = ({ filtersValues, onChangeFilters }: YearsRangeFilterProps) => {
    const { startYear: startYearFilterValue, endYear: endYearFilterValue } = filtersValues;

    useEffect(() => {
        const currentDate = dayjs();
        const currentYear = currentDate.year();
        const isBeforeNewSchoolYear = isBeforeSchoolYear(currentDate, currentYear);
        onChangeFilters?.({
            startYear: isBeforeNewSchoolYear
                ? (currentYear - 1).toString()
                : currentYear.toString(),
            endYear: isBeforeNewSchoolYear ? currentYear.toString() : (currentYear + 1).toString(),
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Filter
            input={
                <Filter.RangePicker
                    picker="year"
                    value={[dayjs().year(startYearFilterValue), dayjs().year(endYearFilterValue)]}
                    onChange={(_, dateStrings) =>
                        onChangeFilters({
                            startYear: dateStrings[0],
                            endYear: dateStrings[1],
                        })
                    }
                />
            }
            label={remoteLessonsTexts.schoolYearsFilter}
            width="200px"
        />
    );
};
