import { useCallback, useEffect } from 'react';
import { useAtomValue, useSetAtom } from 'jotai/index';
import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';
import { handleNetworkError } from 'utils/handleNetworkError';
import { unreadMailThreadsNumberAtom } from 'jotaiAtoms/unreadMailThreadsNumberAtom';
import { userDetailsAtom } from 'jotaiAtoms/userDetails';
import { isStaffUserType } from 'utils/isStaffUserType';
import ParentHTTPService from 'services/HTTPService/parent/ParentHTTPService';
import { useInvalidateMailThreads } from 'hooks/useInvalidateMailThreads/useInvalidateMailThreads';

export const useUnreadMailThreadsStatus = () => {
    const setUnreadMailThreadsNumberState = useSetAtom(unreadMailThreadsNumberAtom);
    const userDetails = useAtomValue(userDetailsAtom);

    const { invalidateMailThreadsData, invalidateUnreadMailThreadsStatusData } =
        useInvalidateMailThreads();

    const userType = userDetails?.type;
    const isUnreadMailThreadsStatusUpdatingDisabled = !userType || userType === 'SYS_ADMIN';
    const isParentUserType = userType ? !isStaffUserType(userType) : undefined;

    const {
        data: unreadMailThreadsData,
        error: unreadMailThreadsError,
        isFetching: isUnreadMailThreadsFetching,
        refetch: refetchUnreadMailThreads,
    } = useAppQuery(
        'UNREAD_MAIL_THREADS',
        [],
        isParentUserType
            ? ParentHTTPService.mailThreads.notifications.getUnreadThreads
            : StaffHTTPService.mailThreads.notifications.getUnreadThreads,
        {
            staleTime: FIVE_MINS_IN_MILLIS,
            enabled: !isUnreadMailThreadsStatusUpdatingDisabled,
        },
    );

    const handleUpdateUnreadMailThreadsStatus = useCallback(async () => {
        if (unreadMailThreadsData === undefined || isUnreadMailThreadsFetching) {
            return;
        }
        setUnreadMailThreadsNumberState(unreadMailThreadsData);
    }, [setUnreadMailThreadsNumberState, unreadMailThreadsData, isUnreadMailThreadsFetching]);

    useEffect(() => {
        handleUpdateUnreadMailThreadsStatus();
    }, [handleUpdateUnreadMailThreadsStatus]);

    const handleWindowsVisibilityChange = useCallback(async () => {
        if (document.visibilityState === 'visible') {
            await invalidateMailThreadsData();
            await invalidateUnreadMailThreadsStatusData();
        }
    }, [invalidateMailThreadsData, invalidateUnreadMailThreadsStatusData]);

    useEffect(() => {
        if (isUnreadMailThreadsStatusUpdatingDisabled) {
            return undefined;
        }
        document.addEventListener('visibilitychange', handleWindowsVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleWindowsVisibilityChange);
        };
    }, [handleWindowsVisibilityChange, isUnreadMailThreadsStatusUpdatingDisabled]);

    if (unreadMailThreadsError) {
        handleNetworkError(unreadMailThreadsError);
    }

    return {
        refetchUnreadMailThreads,
    };
};
