import { useAppQuery } from 'services/reactQuery/useAppQuery';
import StaffHTTPService from 'services/HTTPService/staff/StaffHTTPService';
import { JournalId } from 'types';
import {
    MutationInvalidateQueryKeysType,
    useAppMutation,
} from 'services/reactQuery/useAppMutation';
import { useSimpleNotification } from 'hooks/useSimpleNotification/useSimpleNotification';
import { commonTexts } from 'consts/text';
import { parseStringIntoDayjsDate } from 'utils/parsers/dateTime/parseStringIntoDayjsDate';
import { useFirstAndLastJournalSchoolYears } from 'hooks/useFirstAndLastJournalSchoolYears/useFirstAndLastJournalSchoolYears';
import { getMonthFromDayjsMonth } from 'utils/getMonthFromDayjsMonth';
import { FIVE_MINS_IN_MILLIS } from 'consts/api/staleTime';

type SpecialJournalDailyAttendancePageNetworkManageProps = {
    journalId: JournalId;
    dateFilterValue: string;
};

export const useSpecialJournalDailyAttendancePageNetworkManage = ({
    journalId,
    dateFilterValue,
}: SpecialJournalDailyAttendancePageNetworkManageProps) => {
    const { displayError } = useSimpleNotification();

    const selectedDayjsDate = parseStringIntoDayjsDate(dateFilterValue);
    const selectedYear = selectedDayjsDate.year();
    const selectedMonth = getMonthFromDayjsMonth(selectedDayjsDate.month());

    const dailyAttendanceActionInvalidateQueryKeys: MutationInvalidateQueryKeysType[] = [
        ['SPECIAL_DAILY_ATTENDANCE', journalId, dateFilterValue],
        ['SPECIAL_MONTHLY_ATTENDANCE', journalId, selectedYear, selectedMonth],
        ['SPECIAL_ATTENDANCE'],
    ];

    const {
        data: dailyAttendanceData,
        isInitialLoading: isDailyAttendanceDataInitialLoading,
        isRefetching: isDailyAttendanceDataRefetching,
        error: dailyAttendanceDataError,
    } = useAppQuery(
        'SPECIAL_DAILY_ATTENDANCE',
        [journalId, dateFilterValue],
        () =>
            StaffHTTPService.specialAttendance.getSpecialDailyAttendance(
                journalId,
                dateFilterValue,
            ),
        { staleTime: FIVE_MINS_IN_MILLIS, retry: false },
    );

    const { firstAndLastJournalSchoolYears, isJournalDataFetching, isJournalDataError } =
        useFirstAndLastJournalSchoolYears({ journalId });

    const { mutate: updateDailyAttendance, isLoading: isUpdateDailyAttendanceLoading } =
        useAppMutation(StaffHTTPService.specialAttendance.updateSpecialDailyAttendance, {
            key: ['UPDATE_SPECIAL_DAILY_ATTENDANCE'],
            invalidateQueryKeys: dailyAttendanceActionInvalidateQueryKeys,
            onError: () => displayError(commonTexts.errorMessages.actionExecution),
        });

    return {
        dailyAttendanceData,
        firstAndLastJournalSchoolYears,
        isJournalDataError,
        isDailyAttendanceDataInitialLoading,
        isJournalDataFetching,
        isUpdateDailyAttendanceLoading,
        isDailyAttendanceDataRefetching,
        dailyAttendanceDataError,
        updateDailyAttendance,
    };
};
