import { DiscountDTO, DiscountId, DiscountType } from 'types/discounts/discounts';
import { PaymentId } from 'types/payments/payments';
import { Address, NonNullableObject } from 'types/common';
import { settlementChildrenDTOFields } from 'consts/settlementChildren/settlementChildrenDTOFields';
import { InstitutionId } from 'types/institution';
import { CourseId } from 'types/lesson';
import { ChildId, ChildMealType, ChildSettlementId } from '../child';

export type CostAllocationId = string & { readonly type: unique symbol };

export enum PaymentTypes {
    CASH = 'CASH',
    WIRE_TRANSFER = 'WIRE_TRANSFER',
}

export enum TransferTypes {
    PAYMENT = 'PAYMENT',
    REFUND = 'REFUND',
}

export enum PaymentStatusSettlement {
    IN_PROGRESS = 'IN_PROGRESS',
    CANCELLED = 'CANCELLED',
    RECORDED = 'RECORDED',
}

export type SettlementChildSimpleDTO = {
    [settlementChildrenDTOFields.id]: ChildId;
    [settlementChildrenDTOFields.lastName]: string;
    [settlementChildrenDTOFields.firstName]: string;
    [settlementChildrenDTOFields.institutionId]: InstitutionId;
};

export type SettlementChildDTO = {
    [settlementChildrenDTOFields.child]: SettlementChildSimpleDTO;
    [settlementChildrenDTOFields.mainGroup]: string;
    [settlementChildrenDTOFields.settlementPeriodEnd]: string;
    [settlementChildrenDTOFields.stayCost]: number;
    [settlementChildrenDTOFields.mealCost]: number;
    [settlementChildrenDTOFields.lessonsCost]: number;
    [settlementChildrenDTOFields.id]: ChildSettlementId;
    [settlementChildrenDTOFields.balance]: number;
    [settlementChildrenDTOFields.totalCost]: number;
};

export type PaymentItem = {
    id: PaymentId;
    paymentType: PaymentTypes;
    status: PaymentStatusSettlement;
    amount: number;
    date: string;
};

export type ChildPayment = {
    id: PaymentId;
    date: string;
    amount: number;
    transactionType: TransferTypes;
    paymentFormType: PaymentTypes;
};

export type ChildBillComponent = {
    id: string;
    name: string;
    amountString: string;
    discountString?: string;
    children?: ChildBillComponent[];
};

export type CostAllocationType = PriceDeductionType;

export enum CostAllocationRefersTo {
    STAY = 'STAY',
    MEALS = 'MEALS',
    EXTRA_LESSONS = 'EXTRA_LESSONS',
}

export type ChildCostAllocation = {
    id: CostAllocationId;
    child: {
        childId: ChildId;
        firstName: string;
        lastName: string;
    };
    foundationName: string;
    nip: string;
    bankAccountNumber: string;
    refersTo: CostAllocationRefersTo;
    specificAmount: number | null;
    percentageAmount: number | null;
    type: CostAllocationType;
    archived: boolean;
    address: NonNullableObject<Required<Address>>;
};

export type ChildCostAllocationBasic = Pick<
    ChildCostAllocation,
    'id' | 'foundationName' | 'nip' | 'refersTo' | 'type' | 'specificAmount' | 'percentageAmount'
>;

export enum PriceDeductionType {
    PERCENTAGE = 'PERCENTAGE',
    SPECIFIC_AMOUNT = 'SPECIFIC_AMOUNT',
}

export type ParentChildCostAllocation = {
    organizationName: string;
    NIP: string;
    amount: number;
    costAllocationTarget: string;
    type: PriceDeductionType;
};

export type ChildDiscounts = DiscountDTO[];

export type CostSplitMonthlySettlementPrice = { foundationName: string; foundationCost: number };

type MealDiscount = { name: string; type: DiscountType; amount: number };

type StayDiscount = { discountName: string; type: DiscountType; amount: number };

type StaySettlementPercentageDiscount = {
    id: DiscountId;
    discountName: string;
    percentage: number;
    combinable: boolean;
};

export type DayPriceSummary = {
    priceWithoutDiscounts: number;
    fromDate: string;
    toDate: string;
    staySettlementPercentageDiscounts: StaySettlementPercentageDiscount[];
    priceWithPercentageDiscounts: number;
    discountApplied: boolean;
};

export type HoursPriceSummaryDTO = {
    paidHours: number;
    discounts: StaySettlementPercentageDiscount[];
    pricePerHourWithDiscounts: number;
    totalPriceWithDiscounts: number;
};

export type StaySettlement = {
    daysPriceSummaries: DayPriceSummary[] | null;
    hoursPriceSummaries: HoursPriceSummaryDTO[] | null;
    staySettlementNotPercentageDiscounts: StayDiscount[];
    cost: number;
    freeHours: number;
    refundApplicable: boolean;
    refund: number;
    totalCost: number;
    costSplitMonthlySettlementPrices: CostSplitMonthlySettlementPrice[];
};

export type MealCost = {
    dietName: string;
    mealType: Uppercase<ChildMealType>;
    count: number;
    basePrice: number;
    price: number;
    discounts: DiscountDTO[];
    totalPrice: number;
};

export type MealSettlement = {
    mealCosts: MealCost[];
    discounts: MealDiscount[];
    cost: number;
    refund: number;
    totalCost: number;
    costSplitMonthlySettlementPrices: CostSplitMonthlySettlementPrice[];
};

export type LessonCost = {
    courseId: CourseId;
    name: string;
    basePrice: number;
    price: number;
    discounts: DiscountDTO[];
    totalPrice: number;
    count?: number;
    startDate?: string;
    endDate?: string;
};

type LessonDiscount = {
    id: DiscountId;
    name: string;
    type: DiscountType;
    amount: number;
};

export type LessonSettlement = {
    lessonCosts: LessonCost[];
    discounts: LessonDiscount[];
    cost: number;
    refund: number;
    totalCost: number;
    costSplitMonthlySettlementPrices: CostSplitMonthlySettlementPrice[];
};

type ChildSimpleWithPhotoDTO = {
    id: ChildId;
    firstName: string;
    lastName: string;
    photoUri: string;
};

export type MonthlySettlementSimpleDTO = {
    id: ChildSettlementId;
    startDate: string;
    endDate: string;
    child: ChildSimpleWithPhotoDTO;
    staySettlement: StaySettlement;
    mealSettlement: MealSettlement;
    lessonSettlement: LessonSettlement;
    totalCost: number;
    balance: number;
    recalculable: boolean;
};

export type AddChildCostAllocation = Omit<ChildCostAllocation, 'child' | 'archived' | 'id'>;

export type EditChildCostAllocation = Omit<ChildCostAllocation, 'child' | 'archived'>;

type InstitutionTransferInformationDTO = {
    recipientName: string | null;
    bankAccountNumber: string | null;
    amount: number;
};

type CateringTransferInformationDTO = {
    transferTitle: string | null;
    recipientName: string | null;
    bankAccountNumber: string | null;
    amount: number;
};

export type SettlementTransferInformationDTO = {
    priceTotal: number;
    month: { year: number; monthValue: number };
    childSimpleDto: SettlementChildSimpleDTO;
    institutionTransferInformationDto: InstitutionTransferInformationDTO;
    cateringTransferInformationDto: CateringTransferInformationDTO | null;
};
