import { IconEdit, IconEye, IconTrash } from '@tabler/icons-react';
import { AppTable, ArchiveRestoreModal } from 'components/organisms';
import { commonTexts, remoteLessonsTexts } from 'consts/text';
import { Option } from 'components/atoms';
import { generatePath } from 'react-router-dom';
import { appPaths } from 'consts/paths/paths';
import { RemoteLessonSimpleDTO, RemoteLessonId } from 'types';
import { useState } from 'react';
import { useRemoteLessonAbilities } from 'abilities';
import { useNavigateWithState } from 'hooks/useNavigateWithState/useNavigateWithState';
import { parseFilterParamsIntoGetRemoteLessonsFiltersValues } from 'utils/parsers/remoteLessons/parseFilterParamsIntoGetRemoteLessonsFiltersValues';
import { usePageFilters } from 'hooks/usePageFilters/usePageFilters';
import { columns, filters } from './RemoteLessonsPage.utils';
import { useRemoteLessonsPageNetworkManage } from './hooks/useRemoteLessonsPageNetworkManage';
import { YearsRangeFilter } from './components';

export const RemoteLessonsPage = () => {
    const [selectedToArchiveRemoteLessonId, setSelectedToArchiveRemoteLessonId] =
        useState<RemoteLessonId | null>(null);

    const { statefulNavigate } = useNavigateWithState();
    const { remoteLessonAbilities } = useRemoteLessonAbilities();
    const { checkIfCanManageRemoteLessonsConfig } = remoteLessonAbilities;
    const canManageRemoteLessons = checkIfCanManageRemoteLessonsConfig();

    const { filtersParams, handleChangeFilters } = usePageFilters({ filters });

    const filtersValues = parseFilterParamsIntoGetRemoteLessonsFiltersValues(filtersParams);

    const navigateToRemoteLessonDetails = (remoteLesson: RemoteLessonSimpleDTO) =>
        statefulNavigate(
            generatePath(appPaths.app['remote-lessons'].details.pickedRemoteLesson, {
                remoteLessonId: remoteLesson.id,
            }),
        );

    const navigateToRemoteLessonAdd = () => statefulNavigate(appPaths.app['remote-lessons'].add);

    const navigateToRemoteLessonEdit = (remoteLesson: RemoteLessonSimpleDTO) =>
        statefulNavigate(
            generatePath(appPaths.app['remote-lessons'].edit.pickedRemoteLesson, {
                remoteLessonId: remoteLesson.id,
            }),
        );

    const handleRemoveRemoteLessonClick = ({ id }: RemoteLessonSimpleDTO) =>
        setSelectedToArchiveRemoteLessonId(id);

    const options: Option<RemoteLessonSimpleDTO>[] = [
        {
            label: commonTexts.actionLabels.details,
            onClick: navigateToRemoteLessonDetails,
            Icon: <IconEye />,
        },

        {
            label: commonTexts.actionLabels.edit,
            onClick: navigateToRemoteLessonEdit,
            Icon: <IconEdit />,
            checkIfVisible: ({ editable }) => editable && checkIfCanManageRemoteLessonsConfig(),
        },
        {
            label: commonTexts.actionLabels.delete,
            onClick: handleRemoveRemoteLessonClick,
            Icon: <IconTrash />,
            checkIfVisible: ({ editable }) => editable && checkIfCanManageRemoteLessonsConfig(),
        },
    ];

    const handleUnselectToRemoveRemoteLessonId = () => setSelectedToArchiveRemoteLessonId(null);

    const {
        remoteLessonsData,
        isRemoteLessonsDataInitialLoading,
        isRemoteLessonsDataError,
        isRemoteLessonsDataRefetching,
        removeRemoteLesson,
        isRemoveRemoteLessonLoading,
    } = useRemoteLessonsPageNetworkManage({
        filtersValues,
        onJournalArchiveRequestFinish: handleUnselectToRemoveRemoteLessonId,
    });

    const handleRemoveRemoteLessonConfirm = () => {
        if (!selectedToArchiveRemoteLessonId) {
            return;
        }
        removeRemoteLesson(selectedToArchiveRemoteLessonId);
    };

    return (
        <>
            <ArchiveRestoreModal
                open={!!selectedToArchiveRemoteLessonId}
                title={remoteLessonsTexts.removeRemoteLessonModalTitle}
                confirmActionLoading={isRemoveRemoteLessonLoading}
                onOk={handleRemoveRemoteLessonConfirm}
                onCancel={handleUnselectToRemoveRemoteLessonId}
            />
            <AppTable
                title={remoteLessonsTexts.remoteLessonsList}
                emptyInfo={remoteLessonsTexts.emptyStateInfo}
                options={options}
                columns={columns}
                dataSource={remoteLessonsData || []}
                tableManageUtilsProps={{
                    additionalFilters: (
                        <YearsRangeFilter
                            filtersValues={filtersValues}
                            onChangeFilters={handleChangeFilters}
                        />
                    ),
                    isVisibleSearchFilter: false,
                }}
                rowKey={({ id }) => id}
                isOptionsLoading={isRemoteLessonsDataInitialLoading}
                isError={isRemoteLessonsDataError}
                isDataInitialLoading={isRemoteLessonsDataInitialLoading}
                isDataRefetching={isRemoteLessonsDataRefetching}
                addButtonLabel={
                    canManageRemoteLessons ? remoteLessonsTexts.addRemoteLesson : undefined
                }
                onAddButtonClick={navigateToRemoteLessonAdd}
            />
        </>
    );
};
